// i18next-extract-mark-ns-start contact

import { Helmet } from 'react-helmet'
import React, { FC, useRef } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import { DynamicallyStyledPageTitle } from 'src/components/typography/PageTitle'
import themeColors from 'src/helpers/theme-colors'
import styled from 'styled-components'
import tw from 'twin.macro'
import TextHightlight from 'src/components/typography/TextHightlight'
import AddressBox from 'src/components/contact/AddressBox'
import EmailsBox from 'src/components/contact/EmailsBox'
import LocationMap from 'src/components/contact/LocationMap'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'

const tailwind = resolveConfig(tailwindConfig)

const ScreenContainer = styled.div`
  @media (min-width: ${tailwind.theme.screens.md}) {
    height: 100vh;
    min-height: 930px;
  }
`

const PageTitleWrapper = styled.div`
  h1 {
    ${tw`p-0 m-0`}
  }
`

const HorizontalContainer = styled.div`
  --container-h-margin: 1rem;
  @media (min-width: ${tailwind.theme.screens.sm}) {
    --container-h-margin: calc(50vw - ${tailwind.theme.screens.sm} / 2 + 2rem);
  }
  @media (min-width: ${tailwind.theme.screens.lg}) {
    --container-h-margin: calc(50vw - ${tailwind.theme.screens.lg} / 2 + 4rem);
  }
  @media (min-width: ${tailwind.theme.screens.xl}) {
    --container-h-margin: calc(50vw - ${tailwind.theme.screens.xl} / 2 + 18rem);
  }
  @media (min-width: ${tailwind.theme.screens['2xl']}) {
    --container-h-margin: calc(
      50vw - ${tailwind.theme.screens['2xl']} / 2 + 21rem
    );
  }
  left: var(--container-h-margin);
  right: var(--container-h-margin);
`

const IndexPage: FC = () => {
  const mapRef = useRef(null)
  const mapRect = mapRef.current?.getBoundingClientRect()
  const mapTop = mapRect?.top ?? 0
  const mapHeight = mapRect?.height ?? 1024

  const { t } = useTranslation('contact')
  const titleRef = useRef(null)
  const pointerUpperBound =
    (titleRef.current?.getBoundingClientRect()?.bottom ?? 15) + 7

  const addressBoxRef = useRef(null)
  const pointerLowerBound =
    addressBoxRef.current?.getBoundingClientRect()?.top ?? 768

  const pointerY =
    ((pointerLowerBound + pointerUpperBound) / 2 - mapTop) / mapHeight

  return (
    <>
      <Helmet>
        <title>{t('Contact')}</title>
      </Helmet>
      <Layout>
        <ScreenContainer
          className="relative pt-8 sm:pt-14 md:pt-0"
          ref={mapRef}
        >
          <LocationMap pointerY={pointerY} />
          <PageTitleWrapper
            ref={titleRef}
            className="md:absolute md:top-24 md:left-1/2 md:transform md:-translate-x-2/4 mb-8"
          >
            <DynamicallyStyledPageTitle>
              <TextHightlight
                className="hidden md:block"
                highlightColor={themeColors.almostBlackBlue}
              >
                <span className="text-brightYellow">{t('Contact')}</span>
              </TextHightlight>
              <TextHightlight className="block md:hidden">
                <span className="">{t('Contact')}</span>
              </TextHightlight>
            </DynamicallyStyledPageTitle>
          </PageTitleWrapper>
          <HorizontalContainer className="md:absolute bottom-20 sm:bottom-24 md:bottom-36 md:grid auto-cols-max grid-cols-2 gap-x-0.5 md:bg-black">
            <div ref={addressBoxRef} className="md:sticky inset-x-0">
              <AddressBox className="h-full" />
            </div>
            <EmailsBox />
          </HorizontalContainer>
        </ScreenContainer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
