import React, { FC } from 'react'
import ThinHeader from 'src/components/layout/ThinHeader'

interface IContactInfoBoxProps {
  title: string
  className?: string
}

const ContactInfoBox: FC<IContactInfoBoxProps> = ({
  title,
  className,
  children,
}) => (
  <div className={`flex flex-col ${className ?? ''}`}>
    <ThinHeader className="px-4 whitespace-nowrap overflow-hidden">
      <span>{title}</span>
    </ThinHeader>
    <div className="pt-4 px-4 pb-5 md:pb-10 bg-grayBlue text-white text-lg tracking-wider flex-1 overflow-hidden">
      {children}
    </div>
  </div>
)

export default ContactInfoBox
