import React, { FC } from 'react'
import TextHightlight from 'src/components/typography/TextHightlight'
import tw from 'twin.macro'
import styled from 'styled-components'
import config from 'src/../tailwind.config'

interface IDynamicallyStyledPageTitleProps {
  children: string | JSX.Element | JSX.Element[]
  isSticked?: boolean
}
interface IPageTitleProps extends IDynamicallyStyledPageTitleProps {
  highlightColor?: string
}

const PageTitleStyles = tw`text-center font-thin uppercase text-deepDarkBlue tracking-wider`

const nonStickedStyles = tw`text-4.5xl mb-8 sm:mb-14 md:mb-24 sm:text-6xl md:text-7xl leading-12`
const stickedStyles = tw`mb-0 text-2xl md:text-3xl z-10 py-2.5`

export const StyledPageTitle = styled.h1`
  ${PageTitleStyles}
`

export const DynamicallyStyledPageTitle = styled.h1<IDynamicallyStyledPageTitleProps>`
  ${tw`flex flex-col items-center justify-center`}
  ${PageTitleStyles}
  ${({ isSticked }) => (isSticked ? stickedStyles : nonStickedStyles)}
`

const PageTitle: FC<IPageTitleProps> = ({
  children,
  isSticked,
  highlightColor,
}) => (
  <DynamicallyStyledPageTitle
    isSticked={isSticked}
    style={isSticked ? {} : { minHeight: '100px' }}
  >
    <TextHightlight highlightColor={highlightColor}>
      {typeof children === 'string' ? <div>{children}</div> : children}
    </TextHightlight>
  </DynamicallyStyledPageTitle>
)

PageTitle.defaultProps = {
  highlightColor: config.theme.extend.colors.brightYellow,
}

export default PageTitle
