import React, { FC } from 'react'
import GoogleMapReact from 'google-map-react'
import createMapOptions from 'src/components/contact/createMapOptions'
import LocationSvg from 'src/images/icons/location.svg'

const HQ_LAT = 47.37122642913448
const HQ_LNG = 8.544262829273585

const LocationPointer: FC<GoogleMapReact.Coords> = () => (
  <a
    href="https://www.google.com/maps/search/?api=1&query=The+International+Football+Association+Board&query_place_id=ChIJXd6xrcugmkcRjbZNLAnRmR8"
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={LocationSvg}
      className="w-10 h-10 transform -translate-y-full -translate-x-2/4"
      alt="The IFAB"
    />
  </a>
)

interface ILocationMapProps {
  pointerY: number
  className?: string
}

const LocationMap: FC<ILocationMapProps> = ({ className = '' }) => {
  const handleApiLoaded = (map) => {
    const shiftY = 0.007
    map.setCenter({ lat: HQ_LAT - shiftY, lng: HQ_LNG })
  }

  return (
    <div className={`${className} relative h-full hidden md:block google-maps`}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAba3bBk2ICkNwckR4jhbiTn3OrVwyMbRc',
        }}
        defaultCenter={{
          lat: HQ_LAT,
          lng: HQ_LNG,
        }}
        defaultZoom={14}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
      >
        <LocationPointer lat={HQ_LAT} lng={HQ_LNG} />
      </GoogleMapReact>
    </div>
  )
}

export default LocationMap
