import React, { FC } from 'react'
import { Node } from 'interweave'

interface IThinHeaderProps {
  children: (JSX.Element | JSX.Element[])[] | JSX.Element | Node[]
  className?: string
}

const ThinHeader: FC<IThinHeaderProps> = ({ children, className }) => (
  <div
    className={`bg-almostBlackBlue text-brightYellow font-bold text-xl lg:text-base tracking-wider uppercase py-1 ${
      className || ''
    }`}
  >
    {children}
  </div>
)

export default ThinHeader
