import React, { FC } from 'react'
import ContactInfoBox from 'src/components/contact/ContactInfoBox'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IAddressBoxProps {
  className?: string
}
// TODO Add translations for "Address"
const AddressBox: FC<IAddressBoxProps> = ({ className }) => {
  const { t } = useTranslation('contact')

  return (
    <ContactInfoBox title={t('Address')} className={className}>
      <div className="font-bold mb-2.5">
        <p> The International Football </p>
        <p> Association Board (IFAB) </p>
      </div>
      <div className="leading-normal font-light">
        <p> Münstergasse 9 </p>
        <p> 8001 {t('Zürich')} </p>
        <p> {t('Switzerland')} </p>
      </div>
    </ContactInfoBox>
  )
}

export default AddressBox
